import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  AssetImage,
  LWrap2,
  RecruitNews,
  Vertical,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  WordPicWords,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,

} from "../../../components/_index";
import "../../../assets/_sass/page/recruit/common.scss";
import { title } from "process";
import { useMobile } from "../../../utils/use-series";
const SubPage = ({ data }: { data: any }) => {
    const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "仕事紹介",
    content: "Job Introduction",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
    exClass:"container_left_No1",
    exClassWord: "container_left_sp",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "仕事紹介",
      src: "/recruit/works/",
    },
  ];
  const worksData1 = {
    img1: {
      src:`/assets/images/recruit/works/works6${isSp ? "_sp" : ""}.png`,
      alt: "",
    },
    img2: {
      src:`/assets/images/recruit/works/works7${isSp ? "_sp" : ""}.png`,
      alt: "",
    },
    title: "ホテル運営 料飲部門",
    subtitle: "レストランサービス・バーテンダー・ソムリエ等",
    paragraph: [
      {
        title:
          "お料理のご説明・提供を中心に各レストランやバーでサービスを行っていただきます。",
      },
      {
        title:
          "サービス業務を経て専門職としてバーテンダーやソムリエとなり活躍するスタッフもいます。",
      },

    ],
    paragraphend: "※募集職種はブランド／ホテルにより異なります",
    imgbottomworks: "お客さまとの会話を楽しみながら、長い時間接遇をできることが魅力の一つです",
    position: true,
    jumpid: "jump2",
  };
  const worksData2 = {
    img1: {
      src:`/assets/images/recruit/works/works8${isSp ? "_sp" : ""}.png`,
      alt: "",
    },
    img2: {
      src:`/assets/images/recruit/works/works9${isSp ? "_sp" : ""}.png`,
      alt: "",
    },
    title: "ホテル運営 宴会部門",
    exClassTitle:"pt-5-sp",
    subtitle: "宴会サービス・ブライダル等",
    paragraph2: [
      {
        title: "＜宴会サービス＞",
        title2: "宴会場における宴席や会合、パーティーや結婚式において、会場設営や料理提供を含めた当日の接遇全般に対応します。",

      },
      {
        title: "＜ブライダル＞",
        title2: "プランナーとして、ヒアリングを行いご希望を伺いながら、挙式・披露宴までお二人のサポートを行います。",

      },

    ],
    imgbottomworks: "大切な思い出となるご結婚式やパーティー。大きなやりがいを感じる一方、非常に責任の大きな仕事の一つです。",
    jumpid: "jump3",
    positionSp: true,


  };
  const worksData3 = {
    img1: {
      src:`/assets/images/recruit/works/works10${isSp ? "_sp" : ""}.png`,
      alt: "",
    },
    img2: {
      src:`/assets/images/recruit/works/works11${isSp ? "_sp" : ""}.png`,
      alt: "",
    },
    title: "ホテル運営 調理部門",
    exClassTitle:"pt-5-sp",
    subtitle: "西洋料理、ベーカー、ペストリー、日本料理、中国料理",

    paragraph3: [
      {
        title: "＜西洋料理、ベーカー、ペストリー、日本料理、中国料理＞",
        title2: "それぞれのセクションにおいて、調理業務を担っていただきます。",

      },
      {
        title: "",
        title2: "「食に魅力のあるロイヤルパークホテルズ」を掲げる当社において、大きな役割を担っていただく部門となります。",
      },
    ],
    paragraphend: "※募集職種はブランド／ホテルにより異なります",
    imgbottomworks: "それぞれの分野で多くのスタッフがコンクールにも挑戦しています。",
    position: true,
    jumpid: "jump4",
  };

  const worksData4 = {
    img1: {
      src:`/assets/images/recruit/works/works12${isSp ? "_sp" : ""}.png`,
      alt: "",
    },
    img2: {
      src:`/assets/images/recruit/works/works13${isSp ? "_sp" : ""}.png`,
      alt: "",
    },
    title: "企画・業務",
    exClassTitle:"pt-5-sp",
    subtitle: "",

    paragraph4: [
      {
        title: "＜運営部門＞",
        title2: [
          {
            title: "・プロジェクト推進部（新規開業／ブランド構築／広報等）"
          },
          {
            title: "・運営推進部（レベニューマネジメント／OTA管理／ホテルオペレーション統括等）"
          },
          {
            title: "・セールスマネジメント部（東日本セールス／西日本セールス／企画管理等）"
          },
        ],

      },
      {
        title: "＜コーポレート部門＞",
        title2: [
          {
            title: "・経営企画部"
          },
          {
            title: "・人事部"
          },
          {
            title: "・人財開発部"
          },
          {
            title: "・ITソリューション推進部"
          },
          {
            title: "・購買部"
          },
          {
            title: "・経理部"
          },
          {
            title: "・総務部等"
          },
        ],

      },

    ],
    paragraphend: "",
    imgbottomworks: "ホテル運営全般や、働くスタッフをサポートする部門です。",
    jumpid: "jump5",
    positionSp: true,

  };
  
  return (
    <LayoutSl isKv={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false}/>
      <section className="l_sect04 l_sect04_sp recruit-font">
        {/* <LWrap> */}
        <LWrap2>
          <div className="new_main">
            <ContainerCrumbs data={crumbsData} />
            <div className="container_main container_main_works_full container_main-1-2 ">
              <Vertical data={verticalData} />
              <div className="container_right works-container-right">
                <div className="works_right">
                  <AssetImage
                    src={`/assets/images/recruit/works/works${isSp ? "_sp" : ""}.png`}
                    alt=""
                    loading="lazy"
                  />
                  <AssetImage
                    src={`/assets/images/recruit/works/works3${isSp ? "_sp" : ""}.png`}
                    alt=""
                    loading="lazy"
                  />
                  <AssetImage
                    src={`/assets/images/recruit/works/works2${isSp ? "_sp" : ""}.png`}
                    alt=""
                    loading="lazy"
                  />
                </div>
       
                <p className="m_title_works ">
                  主な仕事内容を紹介いたします。フルサービス型ホテルでは宿泊・料飲・宴会部門のいずれかから、アイコニックブランドと丸ノ内ホテルでは宿泊・料飲のいずれかから、その他宿泊主体型ホテルでは宿泊部門からキャリアをスタートします。< br />
                  調理職については、高校・専門学校・大学等で調理を学んだ方を対象に募集を行っています。
                </p>
                <div className="list-wrapper_works">
                  <ul className="grid-list">
                    <Link className="hover_active" to="#jump1" target="_blank">
                      <li>ホテル運営 宿泊部門
                        <img
                          src="/assets/images/recruit/common/icon-new-bottom.png"
                          alt=""
                        />
                      </li>
                    </Link>
                    <Link className="hover_active" to="#jump2" target="_blank">
                      <li>ホテル運営 料飲部門

                        <img
                          src="/assets/images/recruit/common/icon-new-bottom.png"
                          alt=""
                        />
                      </li>
                    </Link>
                    <Link className="hover_active" to="#jump3" target="_blank">
                      <li>ホテル運営 宴会部門
                        <img
                          src="/assets/images/recruit/common/icon-new-bottom.png"
                          alt=""
                        /></li>
                    </Link>
                    <Link className="hover_active" to="#jump4" target="_blank">
                      <li>ホテル運営 調理部門
                        <img
                          src="/assets/images/recruit/common/icon-new-bottom.png"
                          alt=""
                        />
                      </li>
                    </Link>
                    <Link className="hover_active" to="#jump5" target="_blank">
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企画・業務
                        <img
                          src="/assets/images/recruit/common/icon-new-bottom.png"
                          alt=""
                        />
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="u_grey l_sect04_sp recruit-font">
        <LWrap2>
          <div className="new_main u_grey">
            <div className="container_main_works  works_container">
              <div className="works-title cl-career-sp">
                <h2 id="jump1">ホテル運営 宿泊部門</h2>
              </div>
              <div className="works-subtitle">
                フロント・ゲストリレーション・ベル・ドア・客室サービス等
              </div>
              <div className="works-img">
                <AssetImage
                  src={`/assets/images/recruit/works/works4${isSp ? "_sp" : ""}.png`}
                  alt=""
                  loading="lazy"
                />
                {isSp ? (
                 <></>
                ) : (
                  <AssetImage
                  src={`/assets/images/recruit/works/works5${isSp ? "_sp" : ""}.png`}

                  alt=""
                  loading="lazy"
                />
                )}
              </div>
              <div className="img-word-bottom">
                <div className="img-word-start">ホテルの顔として、ご来館・ご宿泊されるお客さまのそばで、さまざまなご要望にお応えします</div>
                <div className="img-word-content">
                  <h3>＜フロント＞</h3>
                  <div>チェックイン・チェックアウトのお手続きや各種ご案内を行います。</div>
                </div>
                <div className="img-word-content">
                  <h3>＜ゲストリレーション＞</h3>
                  <div>主に専用フロアにご宿泊のお客さまが利用されるラウンジで接遇業務を行います。時にはコンシェルジュのような役割も担っていただきます。</div>
                </div>
                <div className="img-word-content">
                  <h3>＜ベル・ドア＞ </h3>
                  <div>お荷物のお預かりや客室へのご案内、その他ロビーサービス全般を行います。 </div>
                </div>
                <div className="img-word-content">
                  <h3>＜客室サービス＞</h3>
                  <div>清掃以外の客室管理を担うとともに、客室からのお問合せに対応しています。</div>
                </div>
                <div className="img-word-content">
                  <h3></h3>
                  <div>※募集職種はブランド／ホテルにより異なります</div>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="recruit-font">
        <LWrap2>
          <div className="new_main works_container">
          <div className="container_main_works works_container">
            <WordPicWords data={worksData1} />
          </div>
          </div>
        </LWrap2>
      </section>
      <section className="u_grey recruit-font">
        <LWrap2>
          <div className="new_main u_grey works_container">
          <div className="container_main_works works_container ">
            <WordPicWords data={worksData2} />
          </div>
          </div>
        </LWrap2>
      </section>
      <section className="recruit-font">
        <LWrap2>
          <div className="new_main works_container">
          <div className="container_main_works works_container ">
            <WordPicWords data={worksData3} />
          </div>
          </div>
        </LWrap2>
      </section>
      <section className="u_grey recruit-font">
        <LWrap2>
          <div className="new_main u_grey works_container">
          <div className="container_main_works works_container">
            <WordPicWords data={worksData4} />
          </div>
          </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
